<template>
  <div class="my-register-page mb-5">
    <div class="my-container">
      <b-row class="mb-2 mt-3 pt-3">
        <b-col sm="12" md="10">
          <h1>{{ $t("obligations") }}</h1>
        </b-col>
        <b-col class="mt-4" style="text-align:right">
          <b-button
            style="width:100px"
            @click="$router.go(-1)"
            variant="outline-danger"
          >
            {{ $t("back") }}
          </b-button>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          sm="12"
          md="6"
          v-for="(el, index) in ObligationsList"
          :key="index"
        >
          <b-card
            v-if="active == null"
            :style="active == el.value ? ' background-color: #cfe2ff' : ''"
            @click="GetOrgActivity(el)"
            class="card-text m-1 text-center"
            style="min-height: 100px;max-height: 120px;"
          >
            <div style="font-size: 18px;font-weight: 700;">{{ el.text }}</div>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          sm="12"
          md="6"
          class="mt-2"
          v-for="(el, index) in OrgActivityList"
          :key="index"
        >
          <div class="d-flex">
            <b-card
              v-if="index == 0"
              @click="Refresh"
              class="card-parent"
              style="width: 15vw;margin-right: 5px;"
            >
              <img
                src="@/assets/images/menu.png"
                width="24px"
                alt=""
                srcset=""
              />
            </b-card>
            <b-card
              :style="
                activeChild == el.value ? ' background-color: #cfe2ff' : ''
              "
              @click="GetTables(el)"
              class="card-parent"
              style="width: 85vw;"
            >
              {{ el.text }}
            </b-card>
          </div>
        </b-col>
      </b-row>

      <b-table-simple v-if="activeChild != null" bordered class="mt-2">
        <b-thead class="text-center">
          <b-tr variant="primary">
            <b-th style="vertical-align: middle">
              №
            </b-th>
            <b-th style="vertical-align: middle">
              {{ $t("obligationsOfEntrepreneur") }}
            </b-th>

            <b-th style="vertical-align: middle">
              {{ $t("yes") }}
            </b-th>
            <b-th style="vertical-align: middle">
              {{ $t("no") }}
            </b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr v-for="(item, index) in TablesList" :key="index">
            <b-td style="vertical-align: middle">
              {{ index + 1 }}
            </b-td>
            <b-td style="vertical-align: middle">
              {{ item.text }}
            </b-td>

            <b-td class="text-center cursor-pointer" @click="SetChecked(item)">
              <svg
                v-if="item.isChecked"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="green"
                class="bi bi-check-circle"
                viewBox="0 0 16 16"
              >
                <path
                  d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"
                />
                <path
                  d="m10.97 4.97-.02.022-3.473 4.425-2.093-2.094a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05"
                />
              </svg>
            </b-td>
            <b-td class="text-center cursor-pointer" @click="SetChecked(item)">
              <svg
                v-if="!item.isChecked"
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="red"
                class="bi bi-x-circle"
                viewBox="0 0 16 16"
              >
                <path
                  d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"
                />
                <path
                  d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"
                />
              </svg>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
      <b-row v-if="activeChild != null" class="mt-2">
        <b-col>
          <h4 class="text-center">{{ $t("recomend") }}</h4>
          <h6>
            {{ $t("recomendText") }}
          </h6>
        </b-col>
        <b-col
          sm="12"
          md="12"
          lg="12"
          v-for="(item, index) in TablesList"
          :key="index"
        >
          <div v-if="!item.isChecked">
            {{ index + 1 }}. {{ item.recommendationText }}
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import ObligationsService from "@/services/obligations.service";
import ManualService from "../../services/manual.service";
import customSelect from "../../components/elements/customSelect.vue";
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BTableSimple,
  BThead,
  BTbody,
  BTr,
  BTh,
  BTd,
  BIconEyeFill,
  BPagination,
} from "bootstrap-vue";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
    BTableSimple,
    BIconEyeFill,
    BPagination,
    customSelect,
  },
  data() {
    return {
      ObligationsList: [],
      OrgActivityList: [],
      TablesList: [],
      active: null,
      activeChild: null,
      filter: {
        contractorId: null,
      },
    };
  },

  created() {
    this.filter.contractorId = JSON.parse(
      localStorage.getItem("user_info")
    ).contractorId;
    this.Refresh();
    if (
      this.$route.query.obligationOfEntrepreneurId &&
      this.$route.query.organizationActivityTypeId
    ) {
      this.GetOrgActivity({
        value: this.$route.query.obligationOfEntrepreneurId,
      });
      this.GetTables({ value: this.$route.query.obligationOfEntrepreneurId });
    }
  },
  methods: {
    SetChecked(item) {
      item.isChecked = !item.isChecked;
      ObligationsService.SetActivity({
        checkListTableId: item.value,
        isChecked: item.isChecked,
      })
        .then((res) => {
          // this.TablesList = res.data;
        })
        .catch((error) => {
          this.Loading = false;
          this.$message(error.response.data);
        });
    },
    Refresh() {
      this.active = null;
      this.activeChild = null;
      this.OrgActivityList = [];
      this.TablesList = [];
      ObligationsService.GetObligationOrganization()
        .then((res) => {
          this.ObligationsList = res.data;
        })
        .catch((error) => {
          this.$message(error.response.data);
        });
    },
    GetOrgActivity(el) {
      this.active = el.value;
      ObligationsService.GetObligationOrganizationActivityType(el.value)
        .then((res) => {
          this.OrgActivityList = res.data;
        })
        .catch((error) => {
          this.Loading = false;
          this.$message(error.response.data);
        });
    },
    GetTables(el) {
      this.activeChild = el.value;
      ManualService.AsOblOfEntTableSelectList(this.active, el.value)
        .then((res) => {
          this.TablesList = res.data;
        })
        .catch((error) => {
          this.Loading = false;
          this.$message(error.response.data);
        });
    },
  },
};
</script>

<style scoped lang="scss">
.card-parent {
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 12px;
}

.card-text {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 10px;
}
</style>
